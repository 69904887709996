"use client";
import React, { HTMLAttributes } from "react";
import Select from "@/components/primitives/select";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import Icon from "@/components/primitives/icon";
import Button from "../primitives/button";
import { SPORTS, SPORT_OPTIONS } from "@/data/sports/options";
import { Sport, getDefaultSeason, getDefaultYear } from "common";
import styles from "./subnav.module.css";
import Link from "next/link";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/core";
import {
  convertDateToStringWithoutTime,
  formatSeasonYear,
  toDateString,
} from "@/utils";
import { createStandingsSlug, createStatsSlug } from "@/utils/url";
import { usePathname, useRouter } from "next/navigation";

export const TEAM_MENU: Route[] = [
  {
    label: "Overview",
    slug: "",
  },
  {
    label: "Schedule",
    slug: "schedule",
  },
  {
    label: "Stats",
    slug: "stats",
  },
  {
    label: "Roster",
    slug: "roster",
  },
  {
    label: "Injuries",
    slug: "injuries",
  },
  {
    label: "Odds",
    slug: "odds",
  },
  {
    label: "Picks",
    slug: "picks",
  },
];

export const BETTOR_MENU: Route[] = [
  {
    label: "Picks",
    slug: "",
  },
  // {
  //   label: "Activity",
  //   slug: "activity",
  // },
  {
    label: "Pick History",
    slug: "pick-history",
  },
  {
    label: "Awards",
    slug: "awards",
  },
  {
    label: "Following",
    slug: "following",
  },
  // {
  //   label: "Followers",
  //   slug: "followers",
  // },
  {
    label: "About",
    slug: "about",
  },
];

export const BETTING_GUIDE_MENU: Route[] = [
  {
    label: "Sportsbetting 101",
    slug: "sportsbetting-101",
  },
  {
    label: "glossary",
    slug: "glossary",
  },
  {
    label: "faqs",
    slug: "faq",
  },
];
export const SPORTSBETTING_101_MENU: Route[] = [
  {
    label: "Overview",
    slug: "",
  },
  {
    label: "Favorites & Underdogs",
    slug: "favorites-underdogs",
  },
  {
    label: "Odds",
    slug: "odds",
  },
  {
    label: "Spread Bets",
    slug: "spread-bets",
  },
  {
    label: "Moneyline Bets",
    slug: "moneyline-bets",
  },
  {
    label: "Total (O/U) Bets",
    slug: "total-bets",
  },
  {
    label: "Parlay Bets",
    slug: "parlay-bets",
  },
  {
    label: "Same Game Parlays",
    slug: "same-game-parlays",
  },
];

export const DASHBOARD_MENU: Route[] = [
  {
    label: "Dashboard",
    slug: "",
    icon: "layout-grid",
  },
  {
    label: "Feedback",
    slug: "feedback",
    icon: "chat-bubble",
  },
  {
    label: "Reported Comments",
    slug: "reported-comments",
    icon: "user-security",
  },
];

export const SETTINGS_MENU: Route[] = [
  {
    label: "Account Info",
    slug: "",
    icon: "user",
  },
  //   {
  //   label: "Selling Picks",
  //   slug: "selling-picks",
  //   icon: "money-bags",
  // },
  // {
  //   label: "Created Picks",
  //   slug: "created-picks",
  //   icon: "history",
  // },
  // {
  //   label: "Purchased Picks",
  //   slug: "purchased-picks",
  //   icon: "document",
  // },
  {
    label: "Security & Login",
    slug: "security",
    icon: "lock",
  },
  // {
  //   label: "Notifications",
  //   slug: "notifications",
  //   icon: "notification",
  // },
  // {
  //   label: "Payment Settings",
  //   slug: "payment",
  //   icon: "credit-card",
  // },
];

export const ODDS_MENU: Route[] = [
  ...SPORTS.map((sport, _) => ({
    label: sport.label,
    slug: `${sport.id}/odds`,
    icon: sport.icon,
  })),
];

export const PICKS_MENU: Route[] = [
  ...SPORTS.map((sport, _) => ({
    label: sport.label,
    slug: `${sport.id}/picks`,
    icon: sport.icon,
  })),
];

export const NEWS_MENU: Route[] = [
  ...SPORTS.map((sport, _) => ({
    label: sport.label,
    slug: `${sport.id}/news`,
    icon: sport.icon,
  })),
];

export const LEADERBOARD_MENU: Route[] = [
  ...SPORTS.map((sport, _) => ({
    label: sport.label,
    slug: `${sport.id}/leaderboard`,
    icon: sport.icon,
  })),
];

export type SubnavVariant =
  | "sport"
  | "team"
  | "odds"
  | "picks"
  | "bettor"
  | "bettor-mobile"
  | "news"
  | "leaderboard"
  | "sportsbetting-101"
  | "betting-guide"
  | "sportsbook"
  | "settings"
  | "settings-mobile"
  | "dashboard"
  | "dashboard-mobile";
export interface SubnavProps extends HTMLAttributes<HTMLDivElement> {
  variant: SubnavVariant;
  sport?: Sport;
  year?: string;
  hide?: "mobile" | "desktop";
  items?: Route[];
  teamColor?: string;
}

function Subnav(props: SubnavProps) {
  const pathname = usePathname();
  const router = useRouter();
  let menu: Route[];
  let label: string | undefined = undefined;
  let labelLink: string | undefined = undefined;
  let labelWidth: number = 5;
  let targetSlugIndex: number;
  let showSportLinks: boolean = false;
  let useSlider: boolean = true;
  const paths = pathname.split("/");
  const [prevArrowState, setPrevArrowState] = React.useState(false);
  const [nextArrowState, setNextArrowState] = React.useState(false);

  const SPORT_MENU: Route[] = [
    {
      label: "Overview",
      slug: "",
    },
    {
      label: "scores",
      slug: `scores/${toDateString(
        convertDateToStringWithoutTime(new Date())
      )}`,
    },
    {
      label: "Standings",
      slug: `standings`,
    },
    {
      label: "Stats",
      slug: `stats`,
    },
    {
      label: "Injuries",
      slug: "injuries",
    },
    {
      label: "Teams",
      slug: "teams",
    },
    {
      label: "Odds",
      slug: "odds",
    },
    {
      label: "Picks",
      slug: "picks",
    },
    {
      label: "News",
      slug: "news",
    },
    {
      label: "Leaderboard",
      slug: "leaderboard",
    },
  ];

  switch (props.variant) {
    case "sport":
      menu = SPORT_MENU;
      targetSlugIndex = 2;
      break;
    case "team":
      menu = TEAM_MENU;
      targetSlugIndex = 5;
      break;
    case "odds":
      label = "all odds";
      menu = ODDS_MENU;
      targetSlugIndex = 2;
      showSportLinks = true;
      break;
    case "picks":
      label = "all picks";
      menu = PICKS_MENU;
      targetSlugIndex = 2;
      showSportLinks = true;
      break;
    case "leaderboard":
      label = "global leaderboard";
      labelWidth = 10;
      menu = LEADERBOARD_MENU;
      targetSlugIndex = 2;
      showSportLinks = true;
      break;
    case "news":
      label = "all news";
      labelLink = "news";
      menu = NEWS_MENU;
      targetSlugIndex = 2;
      showSportLinks = true;
      break;
    case "bettor":
      menu = BETTOR_MENU;
      targetSlugIndex = 3;
      break;
    case "bettor-mobile":
      menu = BETTOR_MENU;
      targetSlugIndex = 3;
      break;
    case "betting-guide":
      menu = BETTING_GUIDE_MENU;
      targetSlugIndex = 2;
      useSlider = false;
      break;
    case "sportsbetting-101":
      menu = SPORTSBETTING_101_MENU;
      targetSlugIndex = 3;
      break;
    case "sportsbook":
      label = "all sportsbooks";
      labelLink = "sportsbooks";
      labelWidth = 9;
      menu = props.items || [];
      targetSlugIndex = 2;
      break;
    case "settings":
      menu = SETTINGS_MENU;
      useSlider = false;
      targetSlugIndex = 2;
      break;
    case "dashboard":
      menu = DASHBOARD_MENU;
      useSlider = false;
      targetSlugIndex = 2;
      break;
    case "dashboard-mobile":
      menu = DASHBOARD_MENU;
      targetSlugIndex = 2;
      break;
    case "settings-mobile":
      menu = SETTINGS_MENU;
      targetSlugIndex = 2;
      break;
  }

  function buildRoute(slug: string): string {
    switch (targetSlugIndex) {
      case 5:
        return `/${paths[1]}/${paths[2]}/${paths[3]}/${paths[4]}/${slug}`;
      case 4:
        return `/${paths[1]}/${paths[2]}/${paths[3]}/${slug}`;
      case 3:
        return `/${paths[1]}/${paths[2]}/${slug}`;
      case 2:
        return `/${paths[1]}/${slug}`;
      case 1:
        return `/${slug}`;
      default:
        return "/";
    }
  }

  function isActive(slug: string): boolean {
    if (!slug) {
      if (paths.length - 1 < targetSlugIndex) {
        return true;
      }
    } else {
      if (paths.length > targetSlugIndex) {
        if (slug.split("/")[0] === paths[targetSlugIndex]) {
          return true;
        }
      }
    }
    return false;
  }

  function changeSport(value: Sport) {
    if (pathname.includes("game") || pathname.includes("/players/")) {
      router.replace(`/${value}`, {scroll: true});
    } else if (
      pathname.includes("standings") ||
      pathname.includes("stats") ||
      pathname.includes("teams")
    ) {
      if (paths.length >= 2) {
        paths[1] = value;
      }
      const newPathname: string = paths.slice(0, 3).join("/");
      router.replace(newPathname, {scroll: true});

    } else {
      if (paths.length >= 2) {
        paths[1] = value;
      }
      const newPathname: string = paths.join("/");
      router.replace(newPathname, {scroll: true});
    }
  }

  return (
    <div
      className={styles.root}
      data-hide={props.hide}
      data-variant={props.variant}
    >
      <div className={styles.outer}>
        <div className={styles.inner}>
          {props.variant === "sport" ? (
            <div className="min-w-[6.75rem] justify-between flex flex-row items-center h-full">
              <Select
                defaultValue={paths[1]}
                variant="sport"
                label="Sport"
                value={paths[1]}
                onValueChange={changeSport}
                options={SPORT_OPTIONS}
              />
              <div className="h-full w-px bg-grey-soft"></div>
            </div>
          ) : label ? (
            labelLink ? (
              <Link
                href={`/${labelLink}`}
                prefetch={false}
                className="justify-between flex flex-row items-center h-full"
                style={{ minWidth: `${labelWidth}rem` }}
              >
                <div className="text-dark text-title-medium uppercase">
                  {label}
                </div>
                <div className="h-full w-px bg-grey-soft"></div>
              </Link>
            ) : (
              <div
                className="justify-between flex flex-row items-center h-full"
                style={{ minWidth: `${labelWidth}rem` }}
              >
                <div className="text-dark text-title-medium uppercase">
                  {label}
                </div>
                <div className="h-full w-px bg-grey-soft"></div>
              </div>
            )
          ) : (
            <></>
          )}
          <NavigationMenu.Root orientation="horizontal" className={styles.nav}>
            {!useSlider ? (
              <NavigationMenu.List
                className={`${styles.list} flex flex-row items-center`}
              >
                {menu.map((menuItem, i) => (
                  <NavigationMenu.Item
                    value={menuItem.slug}
                    key={i}
                    className="h-full flex items-center"
                  >
                    {showSportLinks ? (
                      <NavigationMenu.Link asChild>
                        <Button
                          href={`/${menuItem.slug}`}
                          variant="sport"
                          className={styles.button}
                        >
                          <div className="flex flex-row gap-2 items-center">
                            {menuItem.icon && (
                              <Icon name={menuItem.icon} size="sm" />
                            )}
                          </div>
                          {menuItem.label}
                        </Button>
                      </NavigationMenu.Link>
                    ) : (
                      <NavigationMenu.Link asChild>
                        <Button
                          variant={
                            props.variant === "settings" ||
                            props.variant === "dashboard"
                              ? "standard"
                              : "tile"
                          }
                          href={buildRoute(menuItem.slug)}
                          className={`${styles.button} group`}
                          data-state={
                            isActive(menuItem.slug) ? "active" : undefined
                          }
                        >
                          {menuItem.icon && (
                            <Icon
                              name={menuItem.icon}
                              className="group-data-[state=active]:text-primary-500 text-secondary-500"
                            />
                          )}
                          <span>{menuItem.label}</span>
                        </Button>
                      </NavigationMenu.Link>
                    )}
                  </NavigationMenu.Item>
                ))}
              </NavigationMenu.List>
            ) : (
              <Splide
                options={{
                  arrows: true,
                  pagination: false,
                  type: "slide",
                  width: "100%",
                  rewind: false,
                  perMove: 3,
                  autoWidth: true,
                  omitEnd: true,
                  gap: "1.5rem",
                  drag: "free",
                  touchMove: true,
                  keyboard: false,
                  height: "100%",
                  breakpoints: {
                    640: {
                      destroy: true,
                    },
                  },
                }}
                className="relative h-full subnav-splide"
                onVisible={(splide, Slide) => {
                  if (Slide.index === 0) {
                    setPrevArrowState(false);
                  }
                  if (Slide.index === menu.length - 1) {
                    setNextArrowState(false);
                  }
                }}
                onHidden={(splide, Slide) => {
                  if (Slide.index === 0) {
                    setPrevArrowState(true);
                  }
                  if (Slide.index === menu.length - 1) {
                    setNextArrowState(true);
                  }
                }}
                onMounted={(splide) => {
                  const lastSlideIsVisible = splide.Components.Slides.getAt(
                    menu.length - 1
                  )?.slide.classList.contains("is-visible");
                  setNextArrowState(!lastSlideIsVisible);

                  const index = menu.findIndex((item) =>
                    pathname.includes(item.slug)
                  );
                  // go to the active slide
                  if (index !== -1) splide.go(index);
                }}
                hasTrack={false}
              >
                <SplideTrack className={styles.splide}>
                  {menu.map((menuItem, i) => (
                    <SplideSlide key={i}>
                      <NavigationMenu.Item
                        value={menuItem.slug}
                        key={i}
                        className="h-full flex items-center"
                        asChild
                      >
                        {showSportLinks ? (
                          <div className="flex items-center h-auto">
                            <NavigationMenu.Link asChild>
                              <Button
                                href={`/${menuItem.slug}`}
                                variant="sport"
                                className={styles.button}
                              >
                                <div className="flex flex-row gap-2 items-center">
                                  {menuItem.icon && (
                                    <Icon name={menuItem.icon} size="sm" />
                                  )}
                                </div>
                                {menuItem.label}
                              </Button>
                            </NavigationMenu.Link>
                          </div>
                        ) : (
                          <NavigationMenu.Link asChild>
                            <Button
                              variant={
                                props.variant === "settings" ||
                                props.variant === "dashboard"
                                  ? "standard"
                                  : "tile"
                              }
                              href={buildRoute(menuItem.slug)}
                              className={styles.button}
                              data-state={
                                isActive(menuItem.slug) ? "active" : undefined
                              }
                              style={
                                props.teamColor
                                  ? ({
                                      "--teamColor": `#${props.teamColor}`,
                                    } as React.CSSProperties)
                                  : undefined
                              }
                            >
                              {menuItem.icon && <Icon name={menuItem.icon} />}
                              <span>{menuItem.label}</span>
                            </Button>
                          </NavigationMenu.Link>
                        )}
                      </NavigationMenu.Item>
                    </SplideSlide>
                  ))}
                </SplideTrack>
                <div className="splide__arrows absolute inset-0 z-10 pointer-events-none">
                  <button
                    className={`splide__arrow splide__arrow--prev absolute left-0 z-10 h-full w-20 flex items-center justify-start transition-opacity bg-gradient-to-r from-40% from-white to-transparent max-[640px]:hidden ${
                      prevArrowState
                        ? "pointer-events-auto"
                        : "opacity-0 pointer-events-none"
                    }`}
                  >
                    <Icon name="caret-left" />
                  </button>
                  <button
                    className={`splide__arrow splide__arrow--next absolute right-0 z-10 h-full w-20 flex items-center justify-end transition-opacity bg-gradient-to-l from-40% from-white to-transparent max-[640px]:hidden ${
                      nextArrowState
                        ? "pointer-events-auto"
                        : "opacity-0 pointer-events-none"
                    }`}
                  >
                    <Icon name="caret-right" />
                  </button>
                </div>
              </Splide>
            )}
          </NavigationMenu.Root>
          {/* <NavigationMenu.Root orientation="horizontal" className={styles.nav}>
            
          </NavigationMenu.Root> */}
        </div>
      </div>
    </div>
  );
}

export default Subnav;
